import language from "../languages";
import homeIcon from "../assets/img/menu/beranda.png";
import studentIcon from "../assets/img/menu/kesiswaan.png";
import curiculumIcon from "../assets/img/menu/kurikulum.png";
import personalIcon from "../assets/img/menu/personalia.png";
import financeIcon from "../assets/img/menu/penganggaran.png";
import administrationIcon from "../assets/img/menu/administrasi.png";
import inventoryIcon from "../assets/img/menu/inventaris.png";
import libraryIcon from "../assets/img/menu/perpustakaan.png";
import settingIcon from "../assets/img/menu/pengaturan.png";

export const SIDEBAR__DASHBOARD_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: "/dashboard",
  },
  {
    title: language.translate.SIDEBAR__STUDENT,
    iconImage: studentIcon,
    path: "/dashboard/kesiswaan",
    permission: "kesiswaan",
    menu: [
      {
        title: "Penerimaan Siswa",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/penerimaan-siswa",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "penerimaan_siswa",
        menu: [
          {
            title: "Penerimaan Siswa Baru",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/list-pendaftaran-ppdb",
            // permission: "ppdb_offline",
          },
          {
            title: "Mutasi Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/list-pendaftaran-siswa",
            permission: "mutasi_siswa",
          },
        ],
      },
      {
        title: "Data Siswa",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/data-siswa",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "data_siswa",
        menu: [
          {
            title: "Daftar Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/laporan/daftar-siswa",
            permission: "daftar_siswa",
          },
		  {
            title: "Laporan PPDB",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/data-siswa/daftar-siswa-ppdb",
            permission: "laporan_ppdb",
          },
          {
            title: "Mutasi Keluar",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/data-siswa/mutasi-keluar",
            permission: "mutasi_keluar",
          },
          {
            title: 'Pembatalan Mutasi Keluar',
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/kesiswaan/data-siswa/pembatalan-mutasi-keluar',
            permission: "pembatalan_mutasi_keluar"
          },
        ],
      },
      {
        title: "Absensi Siswa",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/absensi-siswa",
        className: "menu__left-icon--small",
        permission: "absensi_siswa",
        padding: 20,
        menu: [
          {
            title: "Absensi Harian",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/absensi-siswa/absensi-harian",
			      permission: "absensi_harian",
          },
          {
            title: "Absensi Rekap",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/absensi-siswa/absensi-rekap",
            permission: "absensi_rekap"
          },
          
          {
            title: "Isi per Bidang Study",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study",
            permission: "isi_perbidang_study"
          },
          {
            title: "Laporan Absen Bidang Study",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/absensi-siswa/laporan-absen-bidang-study",
            permission: "laporan_absen_bidang_study"
          },
        ],
      },
      {
        title: "Displiner",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/displiner",
        className: "menu__left-icon--small",
        permission: "displiner",
        padding: 20,
        menu: [
          {
            title: "Table Pelanggaran",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/displiner/table-pelanggaran",
            permission: "table_pelanggaran"
          },
          {
            title: "Table Prestasi",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/displiner/table-prestasi",
            permission: "table_prestasi"
          },
          {
            title: "Catat Pelanggaran",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/displiner/catat-pelanggaran",
            permission: "catat_pelanggaran"
          },
          {
            title: "Catat Prestasi",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/displiner/catat-prestasi",
            permission: "catat_prestasi"
          },
          {
            title: "Catat Harian Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/displiner/catat-harian-siswa",
            permission: "catat_harian_siswa",
          },
        ],
      },
      {
        title: "Ekstrakurikuler",
        icon: "icon-minus",
        path: "/dashboard/kesiswaan/ekstrakulikuler",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "ekskul",
        menu: [
          {
            title: "Jenis Ekstrakulikuler",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler",
            permission: "jenis_ekskul",
          },
          {
            title: "Daftar Peserta Ekskul",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul",
            permission: "daftar_peserta_ekskul"
          },
          {
            title: "Pembagian Kelas Ekskul",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul",
            permission: "pembagian_kelas_ekskul"
          },
          {
            title: "Absensi Ekskul",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul",
            permission: "absensi_eksul"
          },
          {
            title: "Pengisian Nilai Ekskul",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul",
            permission: "pengisian_nilai_ekskul"
          },
        ],
      },
      {
        title: "Bimbingan Konseling",
        path: "/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa",
        icon: "icon-minus",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "bimbingan_konseling"
      },
      {
        title: language.translate.SIDEBAR__STUDENT__STUDENT_REPORT,
        icon: "icon-minus",
        className: "menu__left-icon--small",
        path: "/dashboard/kesiswaan/laporan",
        padding: 20,
        permission: "laporan_kesiswaan",
        menu: [
          // {
          //   title: language.translate.SIDEBAR__STUDENT__STUDENT_REPORT__KLAPPER,
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/laporan/klapper",
          // },
          // {
          //   title: "Laporan 8355",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kesiswaan/laporan/laporan-8355",
          // },
          {
            title: "Laporan Displiner",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/laporan/laporan-displiner",
            permission: "laporan_displiner"
          },
          {
            title: "Laporan Konseling",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kesiswaan/laporan/laporan-konseling",
            permission: "laporan_konseling"

            // },{
            //   title: 'Laporan Absensi Kelas',
            //   icon: 'icon-angle-right',
            //   className: 'menu__left-icon--small',
            //   padding: 40,
            //   path: '/dashboard/kesiswaan/laporan/laporan-absensi-kelas',

            // },{
            //   title: 'Laporan Persensi',
            //   icon: 'icon-angle-right',
            //   className: 'menu__left-icon--small',
            //   padding: 40,
            //   path: '/dashboard/kesiswaan/laporan/laporan-persensi',
          },
        ],
      },
    ],
  },
  {
    // title: language.translate.SIDEBAR__ADMINISTRATION,
    title: "Tata Usaha",
    iconImage: administrationIcon,
    path: "/dashboard/tata-usaha",
    permission: "tata_usaha",
    menu: [
      {
        title: "Manajemen Kelas",
        icon: "icon-minus",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tata-usaha/manajemen-kelas",
        permission: "manajemen_kelas",
        menu: [
          //     {
          //     title: 'Identitas Unit Sekolah',
          //     icon: 'icon-angle-right',
          //     className: 'menu__left-icon--small',
          //     padding: 40,
          //     path: '/dashboard/tata-usaha/manajemen-kelas/identitas-unit-sekolah',

          // },
          // {
          //   title: "Tahun Ajaran Baru",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/tahun-ajaran",
          // },
          // {
          //   title: "Jurusan Kelas",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/jurusan-kelas",
          // },
          // // {
          // //   title: "Kelas",
          // //   icon: "icon-angle-right",
          // //   className: "menu__left-icon--small",
          // //   padding: 40,
          // //   path: "/dashboard/tata-usaha/manajemen-kelas/kelas",
          // // },
          // {
          //   title: "Pembagian Kelas",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas",
          // },
          {
            title: "Kenaikan Kelas Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/kenaikan-kelas-siswa",
            permission: "kenaikan_kelas_siswa",
          },
          {
            title: "Pembagian Kelas PPDB",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas-ppdb",
            permission: "pembagian_kelas_ppdb",
          },
          {
            title: "Pengaturan Kelas Tahun Ajaran Baru",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/pengaturan-kelas-ajaran-baru",
            permission: "laporan_kelas_tahun_ajaran_baru",
          },
          // {
          //   title: 'Kelas Khusus',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/tata-usaha/manajemen-kelas/kelas-khusus',

          // },
          // {
          //   title: 'Pembagian Kelas Khusus',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas-khusus',

          // },
          {
            title: "Siswa Pindah Kelas",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/siswa-pindah-kelas",
            permission: "siswa_pindah_kelas"
          },
            {
            title: "Pembatalan Siswa",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/tata-usaha/manajemen-kelas/pembatalan-siswa",
            permission: "pembatalan_siswa"
          },
          // {
          //   title: "Pindah Unit",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/pindah-unit",
          // },
          // {
          //   title: "Daftar Pindah Unit",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/daftar-pindah-unit",
          // },
          // {
          //   title: "Permohonan Pindah Unit",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/tata-usaha/manajemen-kelas/permohonan-pindah-unit",
          // },
        ],
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__CURRICULUM,
    iconImage: curiculumIcon,
    path: "/dashboard/kurikulum",
	permission: "kurikulum",
    menu: [
      {
        title: "Pengumuman",
        icon: "icon-minus",
        path: "/dashboard/kurikulum/Pengumuman",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "pengumuman",
      },
      {
        title: "Jadwal Kelas & Karyawan",
        icon: "icon-minus",
        path: "/dashboard/kurikulum/jadwal/jadwal-per-kelas",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "jadwal_kelas_dan_guru",
      },
      {
        title: "Ulangan",
        icon: "icon-minus",
        path: "/dashboard/kurikulum/ulangan",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "ulangan",
        menu: [
          // {
          //   title: "Pengisian Indikator Sikap",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kurikulum/ulangan/pengisian-indikator-sikap",
          // },
          {
            title: "Pengisian Nilai",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif",
            permission: "pengisian_nilai",
          },
          // {
          //   title: "Pengisian Aspek Perkembangan & Kompetensi Dasar",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kurikulum/ulangan/pengisian-kompetensi-dasar",
          // },
          // {
          //   // title: "Pengisian Observasi & Daftar Nilai",
          //   title: "Pengisian Daftar Nilai",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kurikulum/ulangan/pengisian-observasi-harian",
          // },
          // {
          //   title: 'Pengisian Observasi Mingguan',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kurikulum/ulangan/pengisian-observasi-mingguan',

          // },{
          //   title: 'Daftar Nilai / Report',
          //   icon: 'icon-angle-right',
          //   className: 'menu__left-icon--small',
          //   padding: 40,
          //   path: '/dashboard/kurikulum/ulangan/daftar-nilai/report',

          // }
          {
            title: "Daftar Nilai",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kurikulum/ulangan/daftar-nilai",
            permission: "daftar_nilai",
          },
          // {
          //   title: "Catatan Anekdot",
          //   icon: "icon-minus",
          //   path: "/dashboard/kurikulum/ulangan/catatan-anekdot",
          //   className: "menu__left-icon--small",
          //   padding: 40,
            
          //   menu: [
          //     {
          //       title: "Tema",
          //       icon: "icon-angle-right",
          //       className: "menu__left-icon--small",
          //       padding: 60,
          //       path: "/dashboard/kurikulum/ulangan/catatan-anekdot/tema",
                
          //     },
          //     {
          //       title: "Sub Tema",
          //       icon: "icon-angle-right",
          //       className: "menu__left-icon--small",
          //       padding: 60,
          //       path: "/dashboard/kurikulum/ulangan/catatan-anekdot/sub-tema",
                
          //     },
          //     {
          //       title: "Pengisian Catatan Anekdot",
          //       icon: "icon-angle-right",
          //       className: "menu__left-icon--small",
          //       padding: 60,
          //       path: "/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot",
                
          //     },
          //   ],
          // },
        ],
      },
      {
        title: "Mata Pelajaran",
        icon: "icon-minus",
        path: "/dashboard/kurikulum/mata-pelajaran",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "mata_pelajaran",
        menu: [
          {
            title: "Kode Mata Pelajaran",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran",
            permission: "kode_mata_pelajaran"
          },
          {
            title: "Daftar Guru",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kurikulum/mata-pelajaran/daftar-guru",
            permission: "daftar_guru"
          },
          {
            title: "Jadwal Matapelajaran",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran",
            permission: "jadwal_matapelajaran",
          },
        ],
      },
      // {
      //   title: "Bobot Penilaian",
      //   icon: "icon-minus",
      //   path: "/dashboard/kurikulum/bobot-penilaian",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Pengisian Bobot Penilaian",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/bobot-penilaian/pengisian-bobot-penilaian",
      //     },
      //   ],
      // },
      // {
      //   title: "Rencana Penilaian",
      //   icon: "icon-minus",
      //   path: "/dashboard/kurikulum/rencana-penilaian",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Upload RPP",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/rencana-penilaian/upload-rpp",
      //     },
      //     {
      //       title: "Pengisian KD",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/rencana-penilaian/pengisian-kd",
      //     },
      //     {
      //       title: "Laporan RPP RPK",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/rencana-penilaian/laporan-rpp-rpk",
      //     },
      //   ],
      // },
      {
        title: "Raport",
        icon: "icon-minus",
        path: "/dashboard/kurikulum/raport",
        className: "menu__left-icon--small",
        padding: 20,
        permission: "raport",
        menu: [
          {
            title: "Daftar Nilai Raport",
            icon: "icon-angle-right",
            className: "menu__left-icon--small",
            padding: 40,
            path: "/dashboard/kurikulum/raport/daftar-nilai-raport",
            permission: "daftar_nilai_raport"
          },
          // {
          //   title: "Raport KBK",
          //   icon: "icon-angle-right",
          //   className: "menu__left-icon--small",
          //   padding: 40,
          //   path: "/dashboard/kurikulum/raport/raport-kbk",
          // },
        ],
      },
      // {
      //   title: "Analisa",
      //   icon: "icon-minus",
      //   path: "/dashboard/kurikulum/analisa",
      //   className: "menu__left-icon--small",
      //   padding: 20,
      //   menu: [
      //     {
      //       title: "Estimasi Kenaikan Kelas",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/analisa/estimasi-kenaikan-kelas",
      //     },
      //     {
      //       title: "Komposisi Siswa",
      //       icon: "icon-angle-right",
      //       className: "menu__left-icon--small",
      //       padding: 40,
      //       path: "/dashboard/kurikulum/analisa/komposisi-siswa",
      //     },
      //   ],
      // },
      //   ,{title: 'List Soal',
      //   icon: 'icon-minus',
      //   path: '/dashboard/kurikulum/analisa',
      //   className: 'menu__left-icon--small',
      //   padding: 20,
      //   menu: [{
      //     title: 'Estimasi Kenaikan Kelas',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/analisa/estimasi-kenaikan-kelas',

      //   },{
      //     title: 'Komposisi Siswa',
      //     icon: 'icon-angle-right',
      //     className: 'menu__left-icon--small',
      //     padding: 40,
      //     path: '/dashboard/kurikulum/analisa/komposisi-siswa',

      //   }
      // ],

      //   }
    ],
  },
  // {
  //   title: "SPP",
  //   className: "menu__left-icon--small",
  //   path: "/dashboard/keuangan/spp",
  //   iconImage: financeIcon,
  //   menu: [
  //     {
  //       title: "Jenis SPP",
  //       icon: "icon-angle-right",
  //       className: "menu__left-icon--small",
  //       padding: 40,
  //       path: "/dashboard/keuangan/spp/jenis-spp",
  //     },
  //     {
  //       title: "Create Invoice",
  //       icon: "icon-angle-right",
  //       className: "menu__left-icon--small",
  //       padding: 40,
  //       path: "/dashboard/keuangan/spp/create-invoice",
  //     },
  //     {
  //       title: "Pembayaran",
  //       icon: "icon-angle-right",
  //       className: "menu__left-icon--small",
  //       padding: 40,
  //       path: "/dashboard/keuangan/spp/pembayaran",
  //     },
  //     {
  //       title: "Laporan SPP",
  //       icon: "icon-angle-right",
  //       className: "menu__left-icon--small",
  //       padding: 40,
  //       path: "/dashboard/keuangan/spp/laporan-spp",
  //     },
  //   ],
  // },
  // {
  //   title: language.translate.SIDEBAR__PERSONAL,
  //   path: "/dashboard/personalia",
  //   iconImage: personalIcon,
  //   menu: [
  //     {
  //       title: language.translate.SIDEBAR__PERSONAL__STAFF_LIST,
  //       path: "/dashboard/personalia/data-staff",
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //     },
  //     {
  //       title: language.translate.SIDEBAR__PERSONAL__PAYROLL_LIST,
  //       path: "/dashboard/personalia/data-payroll",
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //     },
  //     {
  //       title: "Absensi Pegawai",
  //       path: "/dashboard/personalia/absensi-pegawai",
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       menu: [
  //         {
  //           title: "Approval",
  //           path: "/dashboard/personalia/absensi-pegawai/approval",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Laporan Absensi Pegawai",
  //           path: "/dashboard/personalia/absensi-pegawai/laporan-absensi-pegawai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Laporan Absensi Khusus Pegawai",
  //           path: "/dashboard/personalia/absensi-pegawai/laporan-absensi-khusus-pegawai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Laporan Absensi Pegawai Detail",
  //           path: "/dashboard/personalia/absensi-pegawai/laporan-absensi-pegawai-detail",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Pengajuan Cuti Pegawai",
  //           path: "/dashboard/personalia/absensi-pegawai/pengajuan-cuti",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Pengajuan Ijin Pulang Awal",
  //           path: "/dashboard/personalia/absensi-pegawai/pengajuan-ijin-pulang-awal",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Pengajuan Kegiatan Pegawai",
  //           path: "/dashboard/personalia/absensi-pegawai/pengajuan-kegiatan-pegawai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Pengajuan Lembur Pegawai",
  //           path: "/dashboard/personalia/absensi-pegawai/pengajuan-lembur-pegawai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //         {
  //           title: "Table Kegiatan Pegawai",
  //           path: "/dashboard/personalia/absensi-pegawai/table-kegiatan-pegawai",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: language.translate.SIDEBAR__FINANCE,
  //   iconImage: financeIcon,
  //   path: "/dashboard/keuangan",
  //   menu: [
  //     {
  //       title: language.translate.SIDEBAR__FINANCE__BUDGETING,
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       path: "/dashboard/keuangan/penganggaran",
  //       menu: [
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/penganggaran/anggaran",
  //         },
  //         // {
  //         //   title: language.translate.SIDEBAR__FINANCE__BUDGETING__NON_BUDGET,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/penganggaran/non-anggaran',
  //         // }, {
  //         //   title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET_TRANSFER,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/penganggaran/pengalihan-anggaran',
  //         // },
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__BUDGETING__FUND_REQUEST,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/penganggaran/permohonan-dana",
  //         },
  //         // {
  //         // title: language.translate.SIDEBAR__FINANCE__BUDGETING__MONTHLY_ACTIVITY_PLANT,
  //         // icon: 'icon-angle-right',
  //         // className: 'menu__left-icon--small',
  //         // padding: 40,
  //         // path: '/dashboard/keuangan/penganggaran/rencana-kegiatan-bulanan',
  //         // }
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__BUDGETING__REALIZATION,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/penganggaran/realisasi",
  //         },
  //       ],
  //     },
  //     {
  //       title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING,
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       path: "/dashboard/keuangan/pembukuan",
  //       menu: [
  //         {
  //           title: "Saldo Awal",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/pembukuan/saldo-awal",
  //         },
  //         {
  //           title:
  //             language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CASH_JOURNAL,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/pembukuan/jurnal-kas",
  //         },
  //         {
  //           title:
  //             language.translate.SIDEBAR__FINANCE__BOOKKEEPING__BANK_JOURNAL,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/pembukuan/jurnal-bank",
  //         },
  //         // {
  //         //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__PAYMENT_JOURNAL,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/pembukuan/jurnal-pembayaran',
  //         // },
  //         {
  //           title:
  //             language.translate
  //               .SIDEBAR__FINANCE__BOOKKEEPING__ADJUSTMENT_JOURNAL,
  //           title: "Jurnal Umum",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/pembukuan/jurnal-umum",
  //         },
  //         // {
  //         //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CLOSING_JOURNAL,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/pembukuan/jurnal-penutup',
  //         // },
  //         // {
  //         //   title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__NON_KAS_JOURNAL,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/pembukuan/jurnal-non-kas',
  //         // }
  //       ],
  //     },
  //     {
  //       title: language.translate.SIDEBAR__FINANCE__REPORT,
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       path: "/dashboard/keuangan/laporan-keuangan",
  //       padding: 20,
  //       menu: [
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__REPORT__CASH_JOURNAL,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/laporan/jurnal-kas",
  //         },
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__REPORT__BANK_JOURNAL,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/laporan/jurnal-bank",
  //         },
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__REPORT__BIG,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/laporan/buku-besar",
  //         },
  //         // {
  //         //   title: language.translate.SIDEBAR__FINANCE__REPORT__MONTH,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/laporan/transaksi-bulanan',
  //         // },
  //         //  {
  //         //   title: language.translate.SIDEBAR__FINANCE__REPORT__DAILY_MONTH,
  //         //   icon: 'icon-angle-right',
  //         //   className: 'menu__left-icon--small',
  //         //   padding: 40,
  //         //   path: '/dashboard/keuangan/laporan/jurnal-harian-dan-bulanan',
  //         // },
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__REPORT__PROFIT_AND_LOST,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/laporan/laba-rugi",
  //         },
  //         {
  //           title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/keuangan/laporan/neraca",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // //  {
  // //   title: language.translate.SIDEBAR__INVENTORY,
  // //   iconImage: inventoryIcon,
  // //   path:'/dashboard/inventaris',
  // //   menu: [{
  // //     title: 'Tanah',
  // //     path: '/dashboard/inventaris/tanah',
  // //     icon: 'icon-angle-right',
  // //     className: 'menu__left-icon--small',
  // //     padding: 40,
  // //   },{
  // //     title: 'Gedung',
  // //     path: '/dashboard/inventaris/gedung',
  // //     icon: 'icon-angle-right',
  // //     className: 'menu__left-icon--small',
  // //     padding: 40,
  // //   },{
  // //     title: 'Kendaraan',
  // //     path: '/dashboard/inventaris/kendaraan',
  // //     icon: 'icon-angle-right',
  // //     className: 'menu__left-icon--small',
  // //     padding: 40,
  // //   },{
  // //     title: 'Barang',
  // //     path: '/dashboard/inventaris/barang',
  // //     icon: 'icon-angle-right',
  // //     className: 'menu__left-icon--small',
  // //     padding: 40,
  // //   },{
  // //     title: 'Lokasi',
  // //     path: '/dashboard/inventaris/lokasi',
  // //     icon: 'icon-angle-right',
  // //     className: 'menu__left-icon--small',
  // //     padding: 40,
  // //   }],
  // // },
  // {
  //   title: language.translate.SIDEBAR__LIBRARY,
  //   iconImage: libraryIcon,
  //   path: "/dashboard/perpustakaan",
  //   menu: [
  //     {
  //       title: "Data Perpustakaan",
  //       icon: "icon-minus",
  //       className: "menu__left-icon--small",
  //       padding: 20,
  //       path: "/dashboard/perpustakaan/data-perpustakaan",
  //       menu: [
  //         {
  //           title: "Control Perpustakaan",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/control-perpustakaan",
  //         },
  //         {
  //           title: "Buat Klasifikasi",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/buat-klasifikasi",
  //         },
  //         {
  //           title: "Catat Buku atau Catat Majalah",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/catat-buku-atau-catat-majalah",
  //         },
  //         {
  //           title: "Upload",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/upload",
  //         },
  //         {
  //           title: "Buat Asal Buku atau Majalah",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/buat-asal-buku-atau-majalah",
  //         },
  //         {
  //           title: "Top 10 Analysis",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/top-10-analysis",
  //         },
  //         {
  //           title: "Entry Data Pengunjung",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung",
  //           menu: [
  //             {
  //               title: "Data Individu",
  //               icon: "icon-angle-right",
  //               className: "menu__left-icon--small",
  //               padding: 60,
  //               path: "/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung/individu",
  //             },
  //             {
  //               title: "Data Perkelas",
  //               icon: "icon-angle-right",
  //               className: "menu__left-icon--small",
  //               padding: 60,
  //               path: "/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung/perkelas",
  //             },
  //           ],
  //         },
  //         {
  //           title: "Laporan Pemasukan Uang",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/laporan-pemasukan-uang",
  //         },
  //         {
  //           title: "Laporan Pengunjung",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/laporan-pengunjung",
  //         },
  //         {
  //           title: "Laporan Peminjaman",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/laporan-peminjaman",
  //         },
  //         {
  //           title: "Peminjaman Buku",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/peminjaman-buku",
  //         },
  //         {
  //           title: "Pengembalian Buku",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/pengembalian-buku",
  //         },
  //         {
  //           title: "Search Buku",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/search-buku",
  //         },
  //         {
  //           title: "Grafik Analisis",
  //           icon: "icon-angle-right",
  //           className: "menu__left-icon--small",
  //           padding: 40,
  //           path: "/dashboard/perpustakaan/data-perpustakaan/grafik-analisis",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: "Tagihan Siswa",
    className: "menu__left-icon--small",
    path: "/dashboard/tagihan-siswa",
    iconImage: financeIcon,
    permission: "tagihan_siswa",
    menu: [
    //   {
    //     title: "Nama Pembayaran",
    //     icon: "icon-angle-right",
    //     className: "menu__left-icon--small",
    //     padding: 20,
    //     path: "/dashboard/tagihan-siswa/nama-pembayaran",
    //     permission: "nama_pembayaran"
    //   },
      {
        title: "Buat Invoice",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/create-invoice",
        permission: "buat_invoice",
      },
      {
        title: "Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/pembayaran",
        permission: "pembayaran",
      },
      {
        title: "Riwayat Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/riwayat",
        permission: "riwayat_pembayaran",
      },
      {
        title: "Rincian Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/rincian-pembayaran",
        permission: "rincian_pembayaran",
      },
      {
        title: "Edit Tagihan Kelas",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/laporan-pembayaran",
        permission: "edit_tagihan",
      },
      {
        title: "Tunggakan",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/tunggakan",
        permission: "tunggakan",
      }, {
        title: "Tunggakan",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/tunggakan-general",
        permission: "tunggakan_general",
      },
      {
        title: "Laporan Bantuan",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/laporan-bantuan",
        permission: "laporan_bantuan",
      },
       {
        title: "Laporan Pembayaran PPDB",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/laporan-pembayaran-ppdb",
        permission: "laporan_pembayaran_ppdb",
      },
      {
        title: "Laporan Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/laporan-pembayaran-total",
        permission: "laporan_pembayaran",
      },
      {
        title: "Edit Tagihan Siswa",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/tagihan-siswa/edit-tagihan-siswa",
        permission: "edit_tagihan_siswa",
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__SETTING,
    iconImage: settingIcon,
    path: "/dashboard/pengaturan",
    menu: [
      {
        title: "Ganti Kata Sandi",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/ganti-kata-sandi",
        permission: "ganti_kata_sandi"
      },
      {
        title: "Atur Ulang Kata Sandi",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/atur-ulang-kata-sandi",
        permission: "atur_ulang_kata_sandi",
      },
      {
        title: "Blok Unit",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/blok-unit",
        permission: "blok_unit",
      },
      {
        title: "Identitas Unit Sekolah",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/identitas-unit-sekolah",
        permission: "identitas_unit_sekolah",
      },
      {
        title: "Tahun Ajaran Baru",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/tahun-ajaran",
        permission: "tahun_ajaran_baru",
      },
      {
        title: "Pembentukan Kelas",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/kelas",
        permission: "pembentukan_kelas",
      },
      {
        title: "Import Daftar Siswa",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 20,
        path: "/dashboard/pengaturan/import-daftar-siswa",
        permission: "import_daftar_siswa",
      },
    ],
  },
];

export const SIDEBAR_ADMISSION_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: "/admission",
    menu: [
      {
        title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 40,
        path: "/dashboard/keuangan/laporan/neraca",
      },
      {},
    ],
  },
];
