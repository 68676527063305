import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';
import { Link } from "react-router-dom";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanBantuan extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this.getPrmPaymentsGeneralAll = this.getPrmPaymentsGeneralAll.bind(this);
    // this.renderExcel = this.renderExcel.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;

    this.state = {
      page: 1,
      content: initialContent,
      list: [],
      filters:{
        year: "",
      },
      list: [],
      isPrint: true,
      isClick: false,
      form: {
        value: {
          currents_year: "",
          // years_now,
          levels_id,
          listreport: [],
          listreport2: [],
          user_id:user.id
        },
        error: {
          listreport: [],
          listreport2: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id } = user;
    const { levels_id } = school_unit;
    const { content, filters, form } = this.state;
    const { value } = form;
    let savedFilters = JSON.parse(window.localStorage.getItem('invoiceFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('invoiceForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }

    this._initializeData(savedFilters);
    this.getPrmPaymentsGeneralAll();

    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            update: true,
          },
        },
      }));
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { school_unit } = user;
    await this.getYearsOfEducationsOptions({units_id: school_unit.id});
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        tipe : savedFilters.tipe,
      }
    }), () => { 
      // commonActions.setLoading({ show: true });
      this._onSearchContent() 
    });
  }

  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran selanjutnya
        if (res[idx+1]) {
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              year: res[idx+1].value.toString().substring(2),
            },
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                currents_year: res.value
              }
            }
          }))
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async getPrmPaymentsGeneralAll() {
    const { getPrmPaymentsGeneralAll } = this.props;
    const res = await getPrmPaymentsGeneralAll();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    },
    ()=>{
      this._onSearchContent();
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    const { form, param } = this.state;

    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "jenis"){
            const nick_name = param.prm_payments.find(i => i.value ==value)?param.prm_payments.find(i => i.value == value).nick_name:'';
            
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  nick_name: nick_name
                }
              },
            }))
          }
        window.localStorage.setItem('invoiceFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('invoiceForm', JSON.stringify(this.state.form));
        this._onSearchContent();

      }
    );
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { jenis, period, tipe } = filters;
    const { currents_year, years_now, payments_id, month } = form.value
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleReportPPDBSpp, handleReportPPDBUpp, handleReportPPDBOfflineSpp, handleReportPPDBSppGeneral, handleReportPPDBUppGeneral,
            handleReportPPDBOfflineUpp, handleReportPPDBSppF07, handleReportPPDBUppF07, handleListStudentsClassDivGeneral, 
            handleReportPPDBSppRicci, handleReportPPDBUppRicci, handleReportSupportRutin } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id} = user;
          {console.log(school_unit.id)}
          let result = {};
          let result2 = {};
          var year = new Date().getFullYear();
        
            if (tipe === "spp") {
              result = await handleReportSupportRutin({
                ...params,
                currents_year: currents_year?currents_year:year,
                units_id: school_unit.id,
                organizations_id: organizations_id,
                payments_id: payments_id,
                month: month,
                filters,
              });
            }
            else if (tipe === "upp") {
              result2 = await handleReportPPDBUpp({
                ...params,
                organizations_id : organizations_id,
                units_id: school_unit.id,
                filters,
              });
            }
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: result,
                listreport2: result2,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: [],
                listreport2: [],
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async renderExcel() {
    const {form, filters, isClick, isPrint} = this.state;
    const {value} = form;
    const { jenis, period, tipe } = filters;
    const { currents_year, years_now, payments_id, month } = form.value
    const { handleReportSupportRutinExcel } = this.props;
    const { user } = this.props;
    const { school_unit, organizations_id} = user;
    // this.setState({isClick: true})
    // console.log(isClick)
    // if (isClick == true){
      await handleReportSupportRutinExcel({
        currents_year: currents_year,
        units_id: school_unit.id,
        organizations_id: organizations_id,
        payments_id: payments_id,
        month: month,
        filters
      });
    // }
   
    // let excelTbl = [];
    // map(listreport, (list, idx) => {
    //   let items = Object.create({});
    //   items.nisn = list.nisn; 
    //   items.no_va = list.no_va; 
    //   items.full_name = list.full_name; 
    //   items.payments_name = list.payments_name; 
    //   items.month = list.month; 
    //   items.nominal_bantuan = list.nominal_bantuan; 

    //   excelTbl.push(items);
    // });

    // console.log(excelTbl)

    // let rows = [];
    // rows.push([
    // <ExcelFile element={<Button  style={{height:30, width:400, marginRight: 9 }}> Export Excel</Button>}>
    //   <ExcelSheet data={excelTbl} name="excel">
    //     {/* <ExcelColumn label="Kode" value="code3"/> */}
    //     <ExcelColumn style={{fontWeight:'bold'}} label="Jenis Bantuan" value="payments_name"/>
    //     <ExcelColumn style={{fontWeight:'bold'}} label="Bulan" value="month"/>
    //     <ExcelColumn style={{fontWeight:'bold'}} label="No VA" value="no_va"/>
    //     <ExcelColumn style={{fontWeight:'bold'}} label="Nama Lengkap Siswa" value="full_name"/>
    //     <ExcelColumn style={{fontWeight:'bold'}} label="Nominal" value="nominal_bantuan"/>
    //   </ExcelSheet>
    // </ExcelFile>
    // ]);
    // return rows;

  }

  render() {
    const { form, filters, param, page, isPrint, data_idx, isClick } = this.state;
    const { listreport, listreport2, years_now, currents_year, nick_name, month, payments_id } = form.value;
    const { jenis, tipe, period  } = filters;
    const { user } = this.props
    const { school_unit, organization, organizations_id } = user;
    const { logo_right } = organization;
    const { prm_payments } = param;

    const componentRef = React.createRef();
    let prm_payments_bantuan = prm_payments?prm_payments.filter((item) => item.is_type == 0):'';
    let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';
    let prm_rutin = prm_payments_main?prm_payments_main.filter((item) => item.is_routine == 1):'';
    let prm_non_rutin = prm_payments_main?prm_payments_main.filter((item) => item.is_routine == 0):'';
    let prm_payments_nick = prm_payments_bantuan?prm_payments_bantuan.filter((item) => item.nick_name == nick_name):'';

    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;


    const prm_jenis = [
      { label: organizations_id ==3?'SPP':'RUTIN', value: 'spp'},
      { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'upp'},
    ];
    const bulan = [
      { label: 'Juli', value: '7' },
      { label: 'Agustus', value: '8' },
      { label: 'September', value: '9' },
      { label: 'Oktober', value: '10' },
      { label: 'November', value: '11' },
      { label: 'Desember', value: '12' },
      { label: 'Januari', value: '1' },
      { label: 'Februari', value: '2' },
      { label: 'Maret', value: '3' },
      { label: 'April', value: '4' },
      { label: 'Mei', value: '5' },
      { label: 'Juni', value: '6' },
    ];

    var lunas = 0
    var ttllunas = 0
    var subtotal = 0
    var total = 0

    var title = ""
    if (tipe == "spp") {
      title = organizations_id ==3?'SPP':'RUTIN'
    }
    if (tipe == "upp") {
      title = organizations_id ==3?'DPP/UPP':'NON RUTIN'
    }

    let name_payments = prm_payments_nick?
                      (prm_payments_nick.find(i => i.value == payments_id)?
                      prm_payments_nick.find(i => i.value == payments_id).label:'')
                      :''

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Laporan Bantuan</h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="tipe"
                label="Tipe Pembayaran"
                onChange={this._onChangeFilter}
                data={prm_jenis}
                placeholder="Pilihan"
                value={tipe}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
                <Select
                    name="jenis"
                    label="Jenis Pembayaran"
                    onChange={this._onChangeFilter}
                    data={tipe == "spp"?prm_rutin:prm_non_rutin}
                    placeholder="Pilihan"
                    value={jenis}
                />
            </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
                <Select
                    name="payments_id"
                    label="Jenis Bantuan"
                    onChange={this._onFormChange}
                    data={prm_payments_nick}
                    placeholder="Pilihan"
                    value={payments_id}
                />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
                <Select
                    name="currents_year"
                    label="Tahun Ajaran"
                    onChange={this._onFormChange}
                    data={param.periods}
                    // disabled={user_group.name == 'pusat'? false : true}
                    // data={years}
                    placeholder={"Pilihan"}
                    value={currents_year}
                  />
            </div>
          </div>
          {tipe == "spp"?
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
                <Select
                    name="month"
                    label="Bulan"
                    onChange={this._onFormChange}
                    data={bulan}
                    placeholder="Pilihan"
                    value={month}
                />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
             
            </div>
          </div>
          :''}
        </div>

        <br></br>
        <div className="beginning-balance__button-wrapper">
          <ReactToPrint
            pageStyle={printTest}
            onBeforeGetContent={()=> {
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.setState(
                    { isPrint: false },
                    resolve
                  );
                }, 500);
              });
            }}
            onAfterPrint={()=> this.setState({isPrint: true})}
            trigger={() => (
              // <div className="manage-registration__footer">
                <Button title="Cetak/Simpan PDF" />
              // </div>
            )}
            content={() => componentRef.current}
          />
          <Button
           title="Ekspor Excel"
           onClick={() => this.renderExcel()}
          />
             {/* <div style={{marginLeft: '0rem'}} className="student-list__button">
                {this.renderExcel()}
              </div> */}
        </div>
        {/* {isPrint? */}
        <form style={{pageBreakInside: 'avoid', padding: '20px' }} ref={componentRef}>
          <div>
            <table className="table-report-header">
              <tr>
                <th rowSpan="2">
                  <img style={{width:"70px"}} src={logo_right} />
                </th>
                <th style={{ paddingRight: '10rem' }}>
                  <h2>{school_unit.name}</h2>
                </th>
              </tr>
              <tr>
                <th style={{ paddingRight: '10rem' }}>
                  <h3>LAPORAN BANTUAN</h3>
                </th>
              </tr>
            </table>

            {(() => {
              if (!isEmpty(listreport) && tipe == "spp") {
                return (
                  <>
                  <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                    <tr>
                      <td>Jenis Pembayaran</td>
                      <td>&emsp; : {title}</td>
                    </tr>
                    <tr>
                      <td>Tahun Ajaran</td>
                      <td>&emsp; : {currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}</td>
                    </tr>
                    <tr>
                      <td>Jenis Bantuan</td>
                      <td>&emsp; : {name_payments}</td>
                    </tr>
                  </table>
                  <br></br>
                  <table className="tablespp">
                    <thead>
                      <tr>
                        <th rowSpan={2}>No</th>
                        <th rowSpan={2}>No VA</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={12}>Nominal</th>
                      </tr>
                      {/* <tr>
                        {map(bulan, (bln, idx) => (
                          <th>{bln.label}</th>
                        ))}
                      </tr> */}
                    </thead>
                    <tbody>
                    {(() => {
                      total = 0
                    })()}
                      {map(listreport, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {(() => {
                            data.id = data.no_va
                            data.entrance_year = currents_year
                          })()}
                          <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                          <td>{data.no_va}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(data.full_name)}</td>
                          <td className="nominal">{commaFormatted(data.nominal_bantuan)}</td>
                          {(() => {
                            total = total + Number(data.nominal_bantuan)
                          })()}
                        </tr>
                      ))}
                      <tr>
                        <th colSpan={3}>Total</th>
                        <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                      </tr>
                    </tbody>
                  </table>
                  </>
                )
              }
              else if (!isEmpty(listreport2) && tipe == "upp"){
                return (
                  <>
                  <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                    <tr>
                      <td>Jenis Pembayaran</td>
                      <td>&emsp; : {title}</td>
                    </tr>
                    <tr>
                      <td>Tahun Ajaran</td>
                      <td>&emsp; : {currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}</td>
                    </tr>
                    <tr>
                      <td>Jenis Bantuan</td>
                      <td>&emsp; : {currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}</td>
                    </tr>
                  </table>
                  <br></br>
                  <table className="tablespp">
                    <thead>
                      <tr>
                        <th rowSpan={2}>No</th>
                        <th rowSpan={2}>No VA</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={12}>Nominal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(listreport, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                          <td>{data.no_va}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(data.name)}</td>
                          {/* {(() => {
                            if (isEmpty(data.tr_invoices)) {
                              return (
                                <td colSpan={12}>Invoice belum dibuat.</td>
                              )
                            } else {
                              return (
                                map(data.tr_invoices, (data_detail, idx_detail) => {
                                  if (data_detail.payments_date !== null) {
                                    return (
                                      <td className="reportspp" style={{color:"green", textAlign:"center"}}>LUNAS</td>
                                    )
                                  } else {
                                    subtotal = subtotal + Number(data_detail.nominal)
                                    return (
                                      <td className="reportspp">
                                        <Link
                                          to={{
                                            pathname: "/dashboard/tagihan-siswa/create-invoice/rutin",
                                            state: {
                                              getdata: data_detail,
                                              data: data, 
                                              isEdit: true,
                                            },
                                          }}>
                                          {commaFormatted(data_detail.nominal)}
                                        </Link>
                                      </td>
                                    )
                                  }
                                })
                              )
                            }
                          })()} */}
                          <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(subtotal)}</td>
                          {(() => {
                            total = total + subtotal
                          })()}
                        </tr>
                      ))}
                      <tr>
                        <th colSpan={3}>Total</th>
                        <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                      </tr>
                    </tbody>
                  </table>
                  </>
                )
              }
              else if (tipe == undefined || tipe == "") {
                return (
                  <h2 style={{textAlign:"center"}}>Jenis Pembayaran belum dipilih.</h2>
                )
              }
              else {
                return (
                  <h2 style={{textAlign:"center"}}>Data {currents_year} belum ada.</h2>
                )
              }
            })()}
          </div>
        </form>

      </div>
    );
  }
}
LaporanBantuan.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanBantuan.defaultProps = {
  handleGetStudent: noop,
};
