import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Select,
  Input,
  Button,
  Pagination,
  InputDate,
  SearchSelect,
} from "../../../../components/base";
import {
  RIWAYAT_TABLE_FIELD_LIST,
  RIWAYAT_OFFLINE_TABLE_FIELD_LIST,
  RIWAYAT_H2H_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import language from "../../../../languages";

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Riwayat extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    // this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    this._getMsTempsSiswaOptions = this._getMsTempsSiswaOptions.bind(this);
    // this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPrmStudentGeneralOptions = this._getPrmStudentGeneralOptions.bind(this);
    this._getPaymentsTypeOptions = this._getPaymentsTypeOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      list: [],
      filters: {},
      form: {
        value: {
          total: "",
          classes: [],
          levels_id,
          periods: "",
          listhistory: [],
          classes_id: "",
          pg_code: "",
          payload: [],
        },
        error: {
          classes: "",
          levels: "",
          listhistory: "",
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    const { user, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, students_id, payments_type } = state;
    const { school_unit, organizations_id, user_group } = user;
    // const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    // paramTypes.forEach((type) => {
    //   this._getParamOptionsAcademics(type);
    // });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPaymentsTypeOptions();
    if(organizations_id == 3){
      this._getMsTempsSiswaOptions({units_id: [school_unit.va_code] });
    }
    else {
      this._getPrmStudentGeneralOptions({units_id: [school_unit.id] });
    }
    if (isEdit) {
      this._setForm(students_id, payments_type);
    }
    if(user_group.name == 'pusat'){
      this._getSchoolUnitsOptions();
    }
  }

  async _getPrmStudentGeneralOptions(filters = {}) {
    const { getPrmStudentGeneralOptions } = this.props;
    const res = await getPrmStudentGeneralOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getSchoolUnitsOptions(filters={}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id); 
    
    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  async _getPaymentsTypeOptions(filters={}){
    const { getPaymentsTypeOptions, user } = this.props;

    const res = await getPaymentsTypeOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        payments_type: res,
      },
    }));
    
  
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === 'units_id') {
          const param = this.state.param.units;
         
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                units_id : value,
              }
            },
            filters : {
              ...prevState.filters,
              students_id: [],
              payments_type: [],
              date: "",
              date_to: "",
            }
          }));
          if(organizations_id == 3){
            this._getMsTempsSiswaOptions({units_id: [value.value] });
          }
          else {
            this._getPrmStudentGeneralOptions({units_id: [value.value] });
          }
        }
        this._onSearchContent()
      }
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _setForm(students_id, payments_type) {
    try {
      this.setState(
        (prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            students_id: students_id,
            payments_type: payments_type,
          },
        }),
        () => this._onSearchContent()
      );
    } catch (err) {
      // err action
    }
  }

  async _onSearchContent(params = {}) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { filters } = this.state;
    const { students_id } = filters;

    if (filters.date === "") {
      delete filters.date;
    }
    if (filters.date_to === "") {
      delete filters.date_to;
    }

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleHistoryDetailPayments,
            handleHistoryDetailPaymentsOffline,
            handleHistoryDetailPaymentsOfflineGeneral,
            handleHistoryDetailPaymentsH2H,
            handleHistoryPaymentsGeneralRutinVA
          } = this.props;
          let res = "";

          if (filters.payments_type === "paymentgateway") {
            res = handleHistoryDetailPayments;
          }
          if (filters.payments_type === "offline" && organizations_id == 3) {
            res = handleHistoryDetailPaymentsOffline;
          }
          if (filters.payments_type === "offline" && organizations_id !== 3) {
            res = handleHistoryDetailPaymentsOfflineGeneral;
          }
          if (filters.payments_type === "va_bca") {
            res = handleHistoryPaymentsGeneralRutinVA;
          }


          // list h2h
          if (filters.payments_type === "h2h") {
            // res = handleHistoryDetailPaymentsH2H;
            const result = await handleHistoryDetailPaymentsH2H({
              ...params,
              ...filters,
              temps_id: students_id.value,
            });
            this.setState({
              listH2h: result,
            });
          }

          // untuk set state list payment gateway dan offline
          else {
            const {
              result = [],
              currentPage = 1,
              total = 0,
              totalPage = 1,
            } = await res({
              ...params,
              ...filters,
              temps_id: students_id.value,
            });
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          }
        } catch (err) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    );
  }

  // async _getStudentSPPOptions(filters = {}) {
  //   const { getStudentSPPOptions } = this.props;
  //   const res = await getStudentSPPOptions(filters);
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       student: res,
  //     },
  //   }));
  // }

  async _getMsTempsSiswaOptions(filters = {}) {
    const { getMsTempsSiswaOptions } = this.props;
    const res = await getMsTempsSiswaOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  // async _getParamOptionsAcademics(type, filters = {}) {
  //   const { getParamOptionsAcademics, user } = this.props;
  //   const { organizations_id } = user;
  //   if (type === "classrooms" || type === "classrooms") {
  //     filters.organizations_id = organizations_id;
  //   } else {
  //     delete filters.organizations_id;
  //   }
  //   const res = await getParamOptionsAcademics(filters, type);
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       [type]: res,
  //     },
  //   }));
  // }

  // fungsi show detail setelah simpan detail pembayaran
  async onShowDetail(data) {
    const { filters } = this.state;
    const { payments_type } = filters;

    if (payments_type === "paymentgateway") {
      this.openModalShowDetail(data); // buka modal detail
    }
    if (payments_type === "offline") {
      this.openModalShowDetailOffline(data); // buka modal detail
    }
    if (payments_type === "h2h") {
      this.openModalShowDetailH2H(data); // buka modal detail
    }
    if (payments_type === "va_bca") {
      this.openModalShowDetail(data); // buka modal detail
    }
  }

  openModalShowDetail(data) {
    MySwal.fire({
      width: "900px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalShowDetailOffline(data) {
    MySwal.fire({
      width: "900px",
      html: this.renderModalContentShowDetailOffline(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalShowDetailH2H(data) {
    MySwal.fire({
      width: "900px",
      html: this.renderModalContentShowDetailH2H(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  renderModalContentShowDetail(data) {
    const { user } = this.props;
    const { filters } = this.state;
    const { students_id } = filters;

    const componentRef = React.createRef();

    var subtotal = 0;
    let ket = "";
    if (!isEmpty(data.tr_temporary_details)) {
      if (data.tr_temporary_details[0].invoices_id.substring(0, 3) === "INV") {
        ket = "SPP";
      } else {
        ket = "DPP/UPP";
      }
    }
    let name = "";
    if (!isEmpty(students_id)) {
      let str = students_id.label;
      let result = str.indexOf("-");
      name = str.substring(result + 2, str.length);
    }

    return (
      <div>
        <br></br>
        <form class="form" style={{ padding: "20px" }} ref={componentRef}>
          <div style={{ textAlign: "center" }}>
            <h1>Bukti Transaksi Pembayaran {ket}</h1>
          </div>
          <br></br>
          <table
            className="cetak-buku-induk2__table-test"
            style={{ width: "unset" }}
          >
            <tr>
              <td style={{ textAlign: "left" }}>No Invoice</td>
              <td style={{ textAlign: "left" }}>&emsp;: {data.invoices_id}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Nama Siswa</td>
              <td style={{ textAlign: "left" }}>&emsp;: {name}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Status</td>
              <td style={{ textAlign: "left" }}>&emsp;: LUNAS</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Tanggal Pembayaran</td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.month_payments}
              </td>
            </tr>
            {filters.payments_type !== "va_bca"?
            <>
            <tr>
              <td style={{ textAlign: "left" }}>
                Metode Pembayaran
              </td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.bank_name}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>
                Kode Pembayaran
              </td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.trx_id}
              </td>
            </tr>
            </>:''}
          </table>
          <br></br>
          <div>
            <table className="modal-riwayat__table-test">
              {map(data.tr_temporary_details, (item, idx) => (
                <>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight:'bold' }}>
                      {item.status_lable === "Cicilan 0"
                        ? "DP"
                        : item.status_lable}
                    </td>
                    <td style={{ textAlign: "left" }}></td>
                  </tr>

                  {map(item.tr_payment_details, (list, idx) => (
                    <>
                      {(() => {
                        subtotal = subtotal + Number(list.nominal);
                      })()}

                      <tr key={`budget_row_${idx}`} className="invoice">
                        <td style={{ textAlign: "left" }}>{list.name}</td>
                        <td style={{ textAlign: "right" }}>Rp. </td>
                        <td style={{ textAlign: "right" }}>
                          {commaFormatted(list.nominal)}
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
              <tr>
                <th style={{ textAlign: "right" }}>Sub Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(subtotal)}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "right" }}>Biaya Adm.</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.admin_fee)}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "right" }}>Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.total)}
                </th>
              </tr>
            </table>
          </div>
          <br></br>
        </form>
        <br></br>
        <div>
          <ReactToPrint
            trigger={() => (
              <div className="manage-registration__footer">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          />
          <br></br>
          <Button onClick={() => MySwal.close()}>Tutup</Button>
        </div>
        <br></br>
      </div>
    );
  }

  renderModalContentShowDetailOffline(data) {
    const { user } = this.props;
    const { filters } = this.state;
    const { students_id } = filters;
    const componentRef = React.createRef();

    var subtotal = 0;
    let ket = "";
    if (!isEmpty(data.tr_invoice_details)) {
      if (data.invoices_id.substring(0, 3) === "INV") {
        ket = "SPP";
      } else {
        ket = "DPP/UPP";
      }
    }
    let name = "";
    if (!isEmpty(students_id)) {
      let str = students_id.label;
      let result = str.indexOf("-");
      name = str.substring(result + 2, str.length);
    }

    return (
      <div>
        <br></br>
        <form class="form" style={{ padding: "20px" }} ref={componentRef}>
          <div style={{ textAlign: "center" }}>
            <h1>Bukti Transaksi Pembayaran {ket}</h1>
          </div>
          <br></br>
          <table
            className="cetak-buku-induk2__table-test"
            style={{ width: "unset" }}
          >
            <tr>
              <td style={{ textAlign: "left" }}>No Invoice</td>
              <td style={{ textAlign: "left" }}>&emsp;: {data.invoices_id}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Nama Siswa</td>
              <td style={{ textAlign: "left" }}>&emsp;: {name}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Status</td>
              <td style={{ textAlign: "left" }}>&emsp;: LUNAS</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Tanggal Pembayaran</td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.payments_date}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Metode Pembayaran</td>
              <td style={{ textAlign: "left" }}>&emsp;: Offline</td>
            </tr>
            {/* <tr>
              <td style={{ textAlign: "left" }}>
                Kode Pembayaran
              </td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.trx_id}
              </td>
            </tr> */}
          </table>
          <br></br>
          <div>
            <table className="modal-riwayat__table-test">
              {map(data.tr_invoice_details, (item, idx) => (
                <>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight:'bold' }}>
                      {item.status_lable === "Cicilan 0"
                        ? "DP"
                        : item.status_lable}
                    </td>
                    <td style={{ textAlign: "left" }}></td>
                  </tr>
                  {map(item.tr_payment_details, (list, idx) => (
                    <>
                      {(() => {
                        subtotal = subtotal + Number(list.nominal);
                      })()}

                      <tr key={`budget_row_${idx}`} className="invoice">
                        <td style={{ textAlign: "left" }}>{list.name}</td>
                        <td style={{ textAlign: "right" }}>Rp. </td>
                        <td style={{ textAlign: "right" }}>
                          {commaFormatted(list.nominal)}
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
              <tr>
                <th style={{ textAlign: "right" }}>Sub Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(subtotal)}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "right" }}>Biaya Adm.</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.admin_fee)}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "right" }}>Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.total)}
                </th>
              </tr>
            </table>
          </div>
          <br></br>
        </form>
        <br></br>
        <div>
          <ReactToPrint
            trigger={() => (
              <div className="manage-registration__footer">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          />
          <br></br>
          <Button onClick={() => MySwal.close()}>Tutup</Button>
        </div>
        <br></br>
      </div>
    );
  }

  renderModalContentShowDetailH2H(data) {
    const { user } = this.props;
    const { filters } = this.state;
    const { students_id } = filters;

    const componentRef = React.createRef();
    var subtotal = 0;

    let ket = "";
    // if(!isEmpty(data.tr_invoice_details_h2)) {
    //   if(data.invoices_id.substring(0,3) === "INV") {
    //     ket = "SPP";
    //   } else {
    //     ket = "DPP/UPP"
    //   }
    // }
    if (data.invoices_id.substring(0, 3) === "INV") {
      ket = "SPP";
    } else {
      ket = "DPP/UPP";
    }
    let name = "";
    if (!isEmpty(students_id)) {
      let str = students_id.label;
      let result = str.indexOf("-");
      name = str.substring(result + 2, str.length);
    }

    return (
      <div>
        <br></br>
        <form class="form" style={{ padding: "20px" }} ref={componentRef}>
          <div style={{ textAlign: "center" }}>
            <h1>Bukti Transaksi Pembayaran {ket}</h1>
          </div>
          <br></br>
          <table
            className="cetak-buku-induk2__table-test"
            style={{ width: "unset" }}
          >
            <tr>
              <td style={{ textAlign: "left" }}>No Invoice</td>
              <td style={{ textAlign: "left" }}>&emsp;: {data.invoices_id}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Nama Siswa</td>
              <td style={{ textAlign: "left" }}>&emsp;: {name}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Status</td>
              <td style={{ textAlign: "left" }}>&emsp;: LUNAS</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Tanggal Pembayaran</td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.payments_date}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Metode Pembayaran</td>
              <td style={{ textAlign: "left" }}>&emsp;: H2H</td>
            </tr>
            {/* <tr>
              <td style={{ textAlign: "left" }}>
                Kode Pembayaran
              </td>
              <td style={{ textAlign: "left" }}>
                &emsp;: {data.trx_id}
              </td>
            </tr> */}
          </table>
          <br></br>
          <div>
            <table className="modal-riwayat__table-test">
              <tr>
                <td style={{ textAlign: "left", fontWeight:'bold' }}>{data.status_lable}</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              {map(data.tr_invoice_detail_h2h, (list, idx) => {
                subtotal = subtotal + Number(list.nominal);
                if (list.status_lable === "Cicilan 0") {
                  list.status_lable = "DP";
                }
                return (
                  <>
                    <tr key={`budget_row_${idx}`} className="invoice">
                      <td style={{ textAlign: "left" }}>{list.name}</td>
                      <td style={{ textAlign: "right" }}>Rp. </td>
                      <td style={{ textAlign: "right" }}>
                        {commaFormatted(list.nominal)}
                      </td>
                    </tr>
                  </>
                );
              })}
              {/* <tr className="invoice">
                <td style={{ textAlign: "left" }}>
                  {data.status_lable}
                </td>
                <td style={{ textAlign: "right" }}>Rp. </td>
                <td style={{ textAlign: "right" }}>
                  {commaFormatted(data.nominal)}
                </td>
              </tr> */}
              <tr>
                <th style={{ textAlign: "right" }}>Total</th>
                <th style={{ textAlign: "right" }}>Rp. </th>
                <th style={{ textAlign: "right" }}>
                  {commaFormatted(data.total)}
                </th>
              </tr>
            </table>
          </div>
          <br></br>
        </form>
        <br></br>
        <div>
          <ReactToPrint
            trigger={() => (
              <div className="manage-registration__footer">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          />
          <br></br>
          <Button onClick={() => MySwal.close()}>Tutup</Button>
        </div>
        <br></br>
      </div>
    );
  }

  render() {
    const { param, form, content, filters, listH2h } = this.state;
    const { date = "", date_to = "", payments_type, students_id } = filters;
    const { user } = this.props;
    const { profile, username, organizations_id, user_group } = user;

    const paymentstype = [
      { label: "Payment Gateway", value: "paymentgateway" },
      { label: "Offline", value: "offline" },
      { label: "H2H", value: "h2h" },
    ];

    var count = 0;

    if (date > date_to) {
      form.error.date = "Format Tanggal Salah";
      form.error.date_to = "Format Tanggal Salah";
    } else {
      form.error.date = "";
      form.error.date_to = "";
    }
    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Riwayat Pembayaran</h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
        {(user_group.name === 'pusat')? 
              <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="units_id"
                list={param.units}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Unit"
                value={form.value.units_id}
                rightIcon="icon-search"
                label="Pilih Unit"
              />
              </div>
            </div>
            :''}
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Siswa"
                value={students_id}
                // value="061190207"
                rightIcon="icon-search"
                label="Siswa"
              />
            </div>
            {/* <div className="manage-registration__custom-form-row__field">
            </div> */}
          </div>
          <br></br>

          {students_id && (
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Select
                  type="text"
                  name="payments_type"
                  label="Tipe Pembayaran"
                  placeholder="Pilihan"
                  data={organizations_id == 3?paymentstype:param.payments_type}
                  onChange={this._onChangeFilter}
                  value={payments_type}
                />
              </div>
              <div className="manage-registration__custom-form-row__field">
                <InputDate
                  type="date"
                  name="date"
                  label="Dari Tanggal"
                  onChange={this._onChangeFilter}
                  value={date}
                  error={form.error.date || ""}
                />
                <div className="manage-registration__custom-form-row-datadiri">
                  <InputDate
                    type="date"
                    name="date_to"
                    label="Sampai Tanggal"
                    onChange={this._onChangeFilter}
                    value={date_to}
                    error={form.error.date_to || ""}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <br></br>
        {payments_type === "paymentgateway" && (
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">Payment Gateway</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        {map(RIWAYAT_TABLE_FIELD_LIST.label, (field, idx) => (
                          <th
                            key={`table_th_${idx}`}
                            style={{
                              background: "#909090",
                              color: "#FFFFFF",
                              textAlign: "center",
                            }}
                          >
                            {field}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {map(content.list, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {map(
                            RIWAYAT_TABLE_FIELD_LIST.value,
                            (field, fieldIdx) => {
                              if (fieldIdx === 0) {
                                return (
                                  <td
                                    key={`table_index_${fieldIdx}_${idx}`}
                                    style={{ textAlign: "center" }}
                                  >
                                    {idx + 1}
                                  </td>
                                );
                              }
                              if (field.attribute === "total") {
                                return (
                                  <td
                                    key={`table_index_${fieldIdx}_${idx}`}
                                    style={{ align: "right" }}
                                  >
                                    Rp. {commaFormatted(data.total)}
                                  </td>
                                );
                              }
                              return (
                                <td
                                  className={
                                    field.type === "number" ? "nominal" : ""
                                  }
                                  key={`table_${fieldIdx}_${idx}`}
                                >
                                  {formatData(data, field)}
                                </td>
                              );
                            }
                          )}
                          {(() => {
                            if (data.month_payments !== null) {
                              return (
                                <>
                                  <td
                                    style={{
                                      color: "green",
                                      textAlign: "center",
                                    }}
                                  >
                                    LUNAS
                                  </td>
                                  <td>{data.month_payments}</td>
                                  <td>
                                    <Button
                                      onClick={() => this.onShowDetail(data)}
                                      title="Lihat"
                                    />
                                  </td>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <td
                                    style={{
                                      color: "red",
                                      textAlign: "center",
                                    }}
                                  >
                                    DIBATALKAN
                                  </td>
                                  <td></td>
                                  <td></td>
                                </>
                              );
                            }
                          })()}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="user-management__table-footer">
                    <p className="user-management__page-info">
                      {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                    </p>
                    <Pagination
                      totalPage={content.totalPage}
                      currentPage={content.currentPage}
                      onClick={this.onChangePage}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
         {(payments_type === "va_bca") && (
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">VA BCA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" style={{border:"none"}}>
                  <table className="table">
                    <thead>
                      <tr>
                        {map(
                          RIWAYAT_TABLE_FIELD_LIST.label,
                          (field, idx) => (
                            <th
                              key={`table_th_${idx}`}
                              style={{
                                background: "#909090",
                                color: "#FFFFFF",
                                textAlign: "center",
                              }}
                            >
                              {field}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {map(content.list, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {map(
                            RIWAYAT_TABLE_FIELD_LIST.value,
                            (field, fieldIdx) => {
                              if (fieldIdx === 0) {
                                return (
                                  <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                                );
                              }
                              if (field.attribute === "total") {
                                return (
                                  <td key={`table_index_${fieldIdx}_${idx}`} style={{align:"right"}}>Rp. {commaFormatted(data.total)}</td>
                                )
                              }
                              return (
                                <td
                                  className={
                                    field.type === "number" ? "nominal" : ""
                                  }
                                  key={`table_${fieldIdx}_${idx}`}
                                >
                                  {formatData(data, field)}
                                </td>
                              );
                            }
                          )}
                          {(() => {
                            if (data.month_payments !== null){
                              return (
                                <>
                                  <td style={{color:"green", textAlign:"center"}}>LUNAS</td>
                                  <td>{data.month_payments}</td>
                                  <td>
                                    <Button onClick={() => this.onShowDetail(data)} title="Lihat" />
                                  </td>
                                </>
                              )
                            } else {
                              return (
                                <>
                                  <td style={{color:"red", textAlign:"center"}}>DIBATALKAN</td>
                                  <td></td>
                                  <td></td>
                                </>
                              )
                            }
                          })()}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="user-management__table-footer">
                    <p className="user-management__page-info">
                      {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                    </p>
                    <Pagination
                      totalPage={content.totalPage}
                      currentPage={content.currentPage}
                      onClick={this.onChangePage}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {payments_type === "offline" && (
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">Offline</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        {map(
                          RIWAYAT_OFFLINE_TABLE_FIELD_LIST.label,
                          (field, idx) => (
                            <th
                              key={`table_th_${idx}`}
                              style={{
                                background: "#909090",
                                color: "#FFFFFF",
                                textAlign: "center",
                              }}
                            >
                              {field}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {map(
                        content.list,
                        (data, idx) =>
                          !isEmpty(data.tr_invoice_details) && (
                            <tr key={`budget_row_${idx}`}>
                              {map(
                                RIWAYAT_OFFLINE_TABLE_FIELD_LIST.value,
                                (field, fieldIdx) => {
                                  if (fieldIdx === 0) {
                                    return (
                                      <td
                                        key={`table_index_${fieldIdx}_${idx}`}
                                        style={{ textAlign: "center" }}
                                      >
                                        {idx + 1}
                                      </td>
                                    );
                                  }
                                  if (field.attribute === "total") {
                                    return (
                                      <td
                                        key={`table_index_${fieldIdx}_${idx}`}
                                        style={{ align: "right" }}
                                      >
                                        Rp. {commaFormatted(data.total)}
                                      </td>
                                    );
                                  }
                                  return (
                                    <td
                                      className={
                                        field.type === "number" ? "nominal" : ""
                                      }
                                      key={`table_${fieldIdx}_${idx}`}
                                    >
                                      {formatData(data, field)}
                                    </td>
                                  );
                                }
                              )}
                              {(() => {
                                if (data.month_payments !== null) {
                                  return (
                                    <>
                                      <td
                                        style={{
                                          color: "green",
                                          textAlign: "center",
                                        }}
                                      >
                                        LUNAS
                                      </td>
                                      <td>{data.payments_date}</td>
                                      <td>
                                        <Button
                                          onClick={() =>
                                            this.onShowDetail(data)
                                          }
                                          title="Lihat"
                                        />
                                      </td>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <td
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        DIBATALKAN
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </>
                                  );
                                }
                              })()}
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                  <div className="user-management__table-footer">
                    <p className="user-management__page-info">
                      {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                    </p>
                    <Pagination
                      totalPage={content.totalPage}
                      currentPage={content.currentPage}
                      onClick={this.onChangePage}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {payments_type === "h2h" && (
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">H2H</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        {map(
                          RIWAYAT_H2H_TABLE_FIELD_LIST.label,
                          (field, idx) => (
                            <th
                              key={`table_th_${idx}`}
                              style={{
                                background: "#909090",
                                color: "#FFFFFF",
                                textAlign: "center",
                              }}
                            >
                              {field}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {map(listH2h, (data, idx) => (
                        !isEmpty(data.tr_invoice_details_h2) && (
                          <tr key={`budget_row_${idx}`}>
                            {(() => {
                              count++;
                            })()}
                            {map(
                              RIWAYAT_H2H_TABLE_FIELD_LIST.value,
                              (field, fieldIdx) => {
                                if (fieldIdx === 0) {
                                  return (
                                    // <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                                    <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{count}</td>
                                  );
                                }
                                if (field.attribute === "total") {
                                  return (
                                    <td key={`table_index_${fieldIdx}_${idx}`} style={{align:"right"}}>Rp. {commaFormatted(data.total)}</td>
                                  )
                                }
                                return (
                                  <td
                                    className={
                                      field.type === "number" ? "nominal" : ""
                                    }
                                    key={`table_${fieldIdx}_${idx}`}
                                  >
                                    {formatData(data, field)}
                                  </td>
                                );
                              }
                            )}
                            {(() => {
                              // if (data.month_payments !== null){
                                return (
                                  <>
                                    <td style={{color:"green", textAlign:"center"}}>LUNAS</td>
                                    <td>{data.payments_date}</td>
                                    <td>
                                      <Button onClick={() => this.onShowDetail(data)} title="Lihat" />
                                    </td>
                                  </>
                                )
                              // } else {
                              //   return (
                              //     <>
                              //       <td style={{color:"red", textAlign:"center"}}>DIBATALKAN</td>
                              //       <td></td>
                              //       <td></td>
                              //     </>
                              //   )
                              // }
                            })()}
                          </tr>
                        )
                      ))} */}
                      {map(
                        listH2h,
                        (data, idx) =>
                          !isEmpty(data) && (
                            <tr key={`budget_row_${idx}`}>
                              {(() => {
                                count++;
                              })()}
                              {map(
                                RIWAYAT_H2H_TABLE_FIELD_LIST.value,
                                (field, fieldIdx) => {
                                  if (fieldIdx === 0) {
                                    return (
                                      // <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                                      <td
                                        key={`table_index_${fieldIdx}_${idx}`}
                                        style={{ textAlign: "center" }}
                                      >
                                        {count}
                                      </td>
                                    );
                                  }
                                  if (field.attribute === "total") {
                                    return (
                                      <td
                                        key={`table_index_${fieldIdx}_${idx}`}
                                        style={{ align: "right" }}
                                      >
                                        Rp. {commaFormatted(data.total)}
                                      </td>
                                    );
                                  }
                                  return (
                                    <td
                                      className={
                                        field.type === "number" ? "nominal" : ""
                                      }
                                      key={`table_${fieldIdx}_${idx}`}
                                    >
                                      {formatData(data, field)}
                                    </td>
                                  );
                                }
                              )}
                              {(() => {
                                // if (data.month_payments !== null){
                                return (
                                  <>
                                    <td
                                      style={{
                                        color: "green",
                                        textAlign: "center",
                                      }}
                                    >
                                      LUNAS
                                    </td>
                                    <td>{data.payments_date}</td>
                                    <td>
                                      <Button
                                        onClick={() => this.onShowDetail(data)}
                                        title="Lihat"
                                      />
                                    </td>
                                  </>
                                );
                                // } else {
                                //   return (
                                //     <>
                                //       <td style={{color:"red", textAlign:"center"}}>DIBATALKAN</td>
                                //       <td></td>
                                //       <td></td>
                                //     </>
                                //   )
                                // }
                              })()}
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                  {/* <div className="user-management__table-footer">
                    <p className="user-management__page-info">
                      {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                    </p>
                    <Pagination
                      totalPage={content.totalPage}
                      currentPage={content.currentPage}
                      onClick={this.onChangePage}
                    />
                  </div> */}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <br></br>
      </div>
    );
  }
}
Riwayat.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Riwayat.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
