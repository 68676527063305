import { connect } from 'react-redux';
import LaporanPembayaranTotal from '../../../../views/Finance/Spp/LaporanSPP/LaporanPembayaranTotal.view';
import { getStudentSPPOptions, getParamOptionsAcademics, getSchoolUnitsOptions, getPaymentsTypeOptions} from '../../../../states/thunks/options.thunk';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, historyDetailPaymentsH2H, reportTotalPayments, reportTotalPaymentsGeneral } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    // getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleHistoryDetailPayments: (payload, goback) => dispatch(historyDetailPaymentsRutin(payload, goback)),
    handleHistoryDetailPaymentsOffline: (payload, goback) => dispatch(historyDetailPaymentsOffline(payload, goback)),
    handleHistoryDetailPaymentsH2H: (payload, goback) => dispatch(historyDetailPaymentsH2H(payload, goback)),
    handleReportTotalPayments: (payload, goback) => dispatch(reportTotalPayments(payload, goback)),
    handleReportTotalPaymentsGeneral: (payload, goback) => dispatch(reportTotalPaymentsGeneral(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPaymentsTypeOptions: (payload, goback) => dispatch(getPaymentsTypeOptions(payload, goback)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPembayaranTotal);
