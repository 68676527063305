import { connect } from 'react-redux';
import EditTagihanSiswa from '../../../../views/Finance/Spp/LaporanSPP/EditTagihanSiswa.view';
import { historyDetailPaymentsRutin, historyDetailPaymentsOffline, historyDetailPaymentsH2H, reportTotalPayments, listStudentsSpp, listStudentsSppGeneral } from '../../../../states/thunks/academics.thunk';
import { getStudentSPPOptions, getParamOptionsAcademics, getUnitsVaCodeOptions, getMsTempsSiswaOptions, getSchoolUnitsOptions, getPrmStudentGeneral } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    // getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleHistoryDetailPayments: (payload, goback) => dispatch(historyDetailPaymentsRutin(payload, goback)),
    handleHistoryDetailPaymentsOffline: (payload, goback) => dispatch(historyDetailPaymentsOffline(payload, goback)),
    handleHistoryDetailPaymentsH2H: (payload, goback) => dispatch(historyDetailPaymentsH2H(payload, goback)),
    handleReportTotalPayments: (payload, goback) => dispatch(reportTotalPayments(payload, goback)),
    getUnitsVaCodeOptions: (payload) => dispatch(getUnitsVaCodeOptions(payload)),
    getMsTempsSiswaOptions: (payload) => dispatch(getMsTempsSiswaOptions(payload)),
    handleListStudentsSpp: (payload, goback) => dispatch(listStudentsSpp(payload, goback)),
    handleListStudentsSppGeneral: (payload, goback) => dispatch(listStudentsSppGeneral(payload, goback)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
    getPrmStudentGeneralOptions: (payload) => dispatch(getPrmStudentGeneral(payload)),
  
};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTagihanSiswa);
