import React, { Component } from "react";
import PropTypes, { number } from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Button, Select, Input } from "../../../../components/base/index";
import { validateRequiredFields } from "../../../../utils/validation.util";
import { errorAlert } from "../../../../utils/alert.util";

export default class CreateInvoiceTidakRutinGeneral extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onAddListPayments = this._onAddListPayments.bind(this);
    this._onDeleteListPayment = this._onDeleteListPayment.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);

    const { user, location } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { organizations_id, school_unit, user_group } = user;
    const { va_code, unit_code } = school_unit;
    this.state = {
      form: {
        value: {
          va_code: va_code,
          units_id: user_group.name !== 'pusat' ? school_unit.id : data.units_id,
          unit_code: unit_code,
          no_va: data.no_va,
          academics_year: data.entrance_year,
          students_id: data.id,
          name: data.name,
          organizations_id: organizations_id,
          currents_year: "",
          ttl: 0,
          payments_id: "",
          nominal: 0,
          discount: 0,
          ttl_diskon: 0,
          // total: "",
          dp: 0,
          penyesuaian: 0,
          // period: "",
          ttl: 0,
          cicilan: [
            {
              total: 0,
              payments_date: null,
            },
          ],
          tabelpayments: [
            {
              payments_id2: "",
              nominal2: 0,
            },
          ],
        },
        error: {
          cicilan: [],
          tabelpayments: [],
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { school_unit, organizations_id, user_group } = user;

    const paramTypes = ["classrooms", "levels"];
    if (isEdit && data) {
      this._setForm(data);
    }
 
    if(user_group.name == 'pusat'){
      this.getYearsOfEducationsOptions({units_id: data.units_id});
    }
    else{
      this.getYearsOfEducationsOptions({units_id: school_unit.id});
    }

    if (organizations_id == 3) {
      this.getPrmPaymentsNon();
    } else {
      this.getPrmPaymentsNonGeneral();
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { form, param } = this.state;
    const { tabelpayments } = form.value;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (type === "number") {
        formattedValue = Number(value);
      }

      if (inputType == "number") {
        formattedValue = normalizeAmount(value);
      }
      if (
        name === "nominal" ||
        name === "dp" ||
        name === "total" ||
        name === "penyesuaian" ||
        name === "nominal2"
      ) {
        var toNum = value;
        do {
          toNum = toNum.replace(".", "");
        } while (toNum.indexOf(".") != -1);
        formattedValue = Number(toNum);
      }

      if (name === "discount") {
        if (value > 100) {
          formattedValue = 0;
        }
      }
      if (name === "dp" || name === "penyesuaian") {
        if (formattedValue > form.value.nominal - form.value.ttl_diskon) {
          formattedValue = 0;
        }
      }

      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];
     
        if (name === "payments_id2") {
          newList[arrayPosition].error_payments2 = "";
          {
            map(tabelpayments, (data, idx) => {
              const prm = param.prm_payments.find(i =>i.is_type == 1)

              if (
                data.payments_id2 == value || form.value.payments_id == value
              ) {
                formattedValue = "";
                newList[arrayPosition].error_payments2 =
                  "Nama bantuan sudah dipilih";
              }
            });
          }
        }

        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    },
    () => {
      if (name === "payments_id"){
        const nick_name = param.prm_payments.find(i => i.value ==value)?param.prm_payments.find(i => i.value == value).nick_name:'';
        console.log(nick_name)
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              nick_name: nick_name
            }
          },
        }))
      }
    }
    );
  }

  _onAddListPayments(data) {
    const { form } = this.state;
    const { tabelpayments } = form.value;

    tabelpayments.push({
      payments_id2: "",
      nominal2: 0,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelpayments,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteListPayment(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.tabelpayments;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelpayments: list,
      };
    });
  }

  _onAddList(data) {
    const { form } = this.state;
    const { cicilan } = form.value;

    cicilan.push({
      total: 0,
      payments_date: null,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          cicilan,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.cicilan;
      list.splice(idx, 1);
      return {
        ...prevState,
        cicilan: list,
      };
    });
  }

  async onSubmit() {
    const {
      history,
      handleSaveInvoiceNonRutin,
      handleEditInvoiceNonRutin,
      location,
      handleSaveInvoiceNonRutinGeneral,
      user,
      handleEditInvoiceNonRutinGeneral,
      handleSaveInvoiceNonRutinRicciGeneral,
      handleEditInvoiceNonRutinRicciGeneral
    } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { form } = this.state;
    const { value } = form;
    const { cicilan } = value;
    const { organizations_id } = user;
    let canSave = true;
    const error = validateRequiredFields(form.value, [
      "currents_year",
      "payments_id",
      "nominal",
    ]);
    if (value.dp > value.nominal - value.ttl_diskon) {
      error.dp = "Uang Muka melebihi total bayar.";
    }


    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      if (!isEmpty(data.tr_payment_report)) {
        var lunas = 0;
        map(data.tr_payment_report[0].paymentdetails, (list, idx) => {
          if (list.payments_date != null) {
            lunas = lunas + Number(list.nominal);
          }
        });
        if (lunas > form.value.ttl) {
          canSave = false;
          errorAlert({
            title: "Total bayar lebih sedikit dari yang sudah dibayar",
            errorMessage: "Mohon untuk dicek kembali.",
          });
          // error.ttl="Total bayar lebih sedikit dari yang sudah dibayar"
        }
      }
      let payments_date = cicilan.filter((item) => item.payments_date != null);
     

      if (value.ttl <= 0 && payments_date.length == 0) {
        cicilan.splice(0, cicilan.length);
      } else {
        if (isEmpty(cicilan)) {
          canSave = false;
          errorAlert({
            title: "Cicilan Harus Diisi",
            errorMessage: "Mohon tambahkan cicilan terlebih dahulu.",
          });
        } else {
          map(cicilan, (list, idx) => {
            if (list.errcicil || list.errcicil != "") {
              canSave = false;
            } else if (list.total == 0 || list.total == "") {
              canSave = false;
              errorAlert({
                title: 'Cicilan Tidak Boleh "0"',
                errorMessage: "Mohon hapus cicilan yang tidak terpakai.",
              });
            }
          });
        }
      }
      if (form.value.ttl < 0) {
        errorAlert({
          title: "Total tidak boleh minus",
          errorMessage: "Mohon di cek kembali",
        });
      } else {
        const dataForm = form.value;
        const lengthdata = dataForm.tabelpayments.length;
        if (dataForm.currents_year && lengthdata) {
          map(dataForm.tabelpayments, (data, idx) => {
            if (!data.payments_id2) {
              canSave = false;
              errorAlert({
                title: "Harap lengkapi data form",
                errorMessage:
                  "Bagian yang harus diisi ditandai dengan tanda bintang (*)",
              });
            } else if (data.nominal2 == 0 && !data.edit) {
              canSave = false;
              errorAlert({
                title: 'Nominal Tidak Boleh "0"',
                errorMessage: "Mohon hapus pembayaran yang tidak terpakai.",
              });
            }
          });
        } 
        if (canSave) {
          if (isEdit) {
            if (organizations_id == 3) {
              await handleEditInvoiceNonRutin(value);
            } else if(organizations_id == 4 || organizations_id == 5) {
              await handleEditInvoiceNonRutinRicciGeneral(value);
            } else {
              await handleEditInvoiceNonRutinGeneral(value);
            }
          } else {
            if (organizations_id == 3) {
              await handleSaveInvoiceNonRutin(value);
            } else if(organizations_id == 4 || organizations_id == 5) {
              await handleSaveInvoiceNonRutinRicciGeneral(value);
            } else {
              await handleSaveInvoiceNonRutinGeneral(value);
            }
          }
          history.goBack();
          // history.push("/dashboard/tagihan-siswa/create-invoice");
        }
      }
    }
  }

  async _setForm(data) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { form } = this.state;

    const cicilan = data.tr_payment_report[0].paymentdetails;
    const bantuan = data.tr_payment_report[0].tr_payment_help;
    var dp_payments_date = null;
    var dp_invoices_id = null;


    if (data.tr_payment_report[0].dp != "0") {
      dp_payments_date =
        data.tr_payment_report[0].paymentdetails[0].payments_date;
      dp_invoices_id = data.tr_payment_report[0].paymentdetails[0].invoices_id;
      cicilan.shift();
    }

    // if (form.value.penyesuaian & dp_payments_date == null) {
    //   form.value.dp = 0;
    // }

    try {
      this.setState({
        form: {
          value: {
            users_id: data.tr_payment_report[0].users_id,
            no_va: data.no_va,
            name: data.name,
            // address: data.address,
            academics_year: data.tr_payment_report[0].academics_year,
            entrance_year: data.tr_payment_report[0].academics_year,
            id: data.tr_payment_report[0].id,
            students_id: data.id,
            organizations_id: organizations_id,
            currents_year: data.tr_payment_report[0].currents_year,
            payments_id: data.tr_payment_report[0].payments_id,
            nominal: data.tr_payment_report[0].nominal,
            discount: data.tr_payment_report[0].discount,
            nick_name: data.tr_payment_report[0].nick_name,
            // ttl_diskon:form.value.ttl_diskon ? form.value.ttl_diskon : "0",
            dp: data.tr_payment_report[0].dp,
            penyesuaian: data.tr_payment_report[0].penyesuaian,
            dp_payments_date: dp_payments_date,
            dp_invoices_id: dp_invoices_id,
            // period:form.value.period ? form.value.period : "0",
            // ttl:form.value.ttl,
            cicilan: map(cicilan, (detail) => ({
              total: detail.nominal,
              payments_date: detail.payments_date,
              invoices_id: detail.invoices_id,
              // edit:true,
            })),
            tabelpayments: map(bantuan, (detail) => ({
              payments_id2: detail.payments_id,
              nominal2: detail.nominal,
              id: detail.id,
              edit: true,
            })),
          },
          error: {},
        },
      });
    } catch (err) {
      // err action
    }
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  async getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false } = state;

    const res = await getYearsOfEducationsOptions(filters);

    if (!isEdit) {
      map(res, (data, idx) => {
        var date = new Date();
        var start_date = new Date(data.attributes.start_date);
        if (date > start_date) {
          // tahun ajaran selanjutnya
          if (res[idx + 1]) {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  nextyear: res[idx + 1].value,
                },
              },
            }));
          }
        }
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { school_unit, organizations_id } = user;
    const { form, param } = this.state;
    const { prm_payments } = param;
    const { cicilan, nextyear, tabelpayments, nick_name } = form.value;
    console.log(form.value)
    const prm_period = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" },
    ];

    form.value.currents_year = form.value.currents_year
      ? form.value.currents_year
      : nextyear;

    form.value.ttl_diskon = Math.ceil(
      (Number(form.value.nominal) * Number(form.value.discount)) / 100
    );
    form.value.ttl = Number(form.value.nominal) - Number(form.value.ttl_diskon) - Number(form.value.dp) -
      Number(form.value.penyesuaian) ;

      // form.value.ttl = 0;
      {map(tabelpayments, (list, idx) => {
        if (list.payments_id2 && !isEmpty(param.prm_payments)) {
          list.is_discount = param.prm_payments.find(i => i.value == list.payments_id2).is_discount
          if (list.is_discount == 1) {
            form.value.ttl-=Number(list.nominal2)
          } else {
            form.value.ttl+=Number(list.nominal2)
          }
        }
      })}

    // if (form.value.ttl == 0 || form.value.period == "") {
    //     form.value.period = 0
    // }

    if (form.value.discount == "") {
      form.value.discount = 0;
    }

    // if(Number(form.value.period) !== 0 || form.value.period !="") {
    //   form.value.total = Math.ceil(form.value.ttl/Number(form.value.period));
    // }
    // else{
    //   form.value.total = Math.ceil(form.value.ttl);
    // }
    // if (form.value.ttl == 0) {
    //   cicilan.splice(0, cicilan.length);
    // }

    var sisacicil = form.value.ttl;
    map(cicilan, (list, idx) => {
      sisacicil = sisacicil - list.total;
    });

    if (!isEmpty(cicilan)) {
      var last = cicilan.length - 1;
      map(cicilan, (list, idx) => {
        // if (list.total == 0) {
        //   list.errcicil = "Cicilan tidak boleh 0"
        // } else
        if (sisacicil == 0 || idx != last) {
          list.errcicil = "";
        } else {
          if (sisacicil < 0) {
            list.errcicil = "Cicilan Lebih Rp. " + commaFormatted(sisacicil);
          } else {
            list.errcicil = "Sisa Cicilan Rp. " + commaFormatted(sisacicil);
          }
        }
      });
    }

    if (form.value.ttl >= form.value.dp) {
      form.error.dp = "";
    }

    if (form.value.ttl >= form.value.penyesuaian) {
      form.error.penyesuaian = "";
    }

    if (form.value.penyesuaian != 0 && form.value.dp_payments_date == null) {
      form.value.dp = 0;
    }
    // let nick_edit = tabelpayments[0]?tabelpayments[0].nick_name:'';
    // let nick = nick_name?nick_name:nick_edit;

    let payments_date = cicilan.filter((item) => item.payments_date != null);
    let prm_payments_bantuan = prm_payments?prm_payments.filter((item) => item.is_type == 0):'';
    let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';
    let prm_payments_nick = prm_payments_bantuan?prm_payments_bantuan.filter((item) => item.nick_name == nick_name):'';
    
    // console.log("NICK "+nick)
    console.log(prm_payments_nick)
    console.log(nick_name)
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>{organizations_id == 3 ? "DPP/UPP" : "NON RUTIN"}</h1>
          <hr></hr>
        </div>
        <form>
          <div className="result__content">
            <div className="nilai-kognitif__custom-form">
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="no_va"
                    label="No. VA"
                    value={data ? data.no_va : ""}
                    onChange={this._onFormChange}
                    error={form.error.no_va || ""}
                    readOnly
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Input
                    name="name"
                    label="Nama"
                    value={data ? data.name : ""}
                    onChange={this._onFormChange}
                    error={form.error.name || ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="entrance_year"
                    label="Tahun Masuk"
                    value={
                      isEdit
                        ? form.value.entrance_year
                        : data
                        ? data.entrance_year
                        : ""
                    }
                    onChange={this._onFormChange}
                    error={form.error.entrance_year || ""}
                    readOnly
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  {/* <Select
                    name="currents_year"
                    label="Tahun Ajaran *"
                    onChange={this._onFormChange}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={form.value.currents_year || ""}
                    error={form.error.currents_year}
                    isRequired
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <table className="manage-rapbs-plafon__form-table table2-invoice">
            <thead>
              <tr>
                <th colSpan="2" className="head">
                  Data
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="noBorder">
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field">
                      <Select
                        label="Nama Pembayaran *"
                        name="payments_id"
                        onChange={this._onFormChange}
                        placeholder="Pilihan"
                        data={prm_payments_main}
                        value={form.value.payments_id}
                        error={form.error.payments_id}
                        isRequired
                        disabled={isEdit ? true : false}
                      />
                    </div>
                    <div
                      className="kode-matpel__custom-form-column__field"
                      style={{ flexDirection: "unset" }}
                    >
                      <label
                        style={{ paddingTop: "30px", paddingRight: "20px" }}
                      >
                        Rp.
                      </label>
                      <Input
                        // type="number"
                        name="nominal"
                        label="Nominal *"
                        onChange={this._onFormChange}
                        value={form.value.nominal}
                        error={form.error.nominal}
                        isNumber
                        isRequired
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="helping">

                </td>
              </tr>
              {map(tabelpayments, (list, idx) => (
                <tr>
                  <td>
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                        <Select
                          label="Nama Bantuan *"
                          name="payments_id2"
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          // data={organizations_id == 3?param.prm_payments:param.prm_payments_general}
                          // data={prm_payments_bantuan}
                          data={prm_payments_nick}
                          value={list.payments_id2}
                          error={list.error_payments2}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                          disabled={list.edit ? true : false}
                        />
                      </div>
                      <div
                        className="kode-matpel__custom-form-column__field"
                        style={{ flexDirection: "unset" }}
                      >
                        <label
                          style={{ paddingTop: "30px", paddingRight: "20px" }}
                        >
                          Rp.
                        </label>
                        <Input
                          // type="number"
                          name="nominal2"
                          label={"Nominal Bantuan" + (list.edit ? "" : "*")}
                          onChange={this._onFormChange}
                          value={list.nominal2}
                          // error={form.error.nominal}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                          isNumber
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    {!list.edit && (
                      <Button
                        onClick={() => this._onDeleteListPayment(idx)}
                        icon="icon-trash-o"
                      ></Button>
                    )}
                  </td>
                </tr>
              ))}
              <tr className="manage-rapbs-plafon__add-more">
                <td colSpan={2} className="helping">
                  <div>
                    <Button
                      icon="icon-plus"
                      onClick={this._onAddListPayments}
                    ></Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field"></div>
                    <div
                      className="kode-matpel__custom-form-column__field"
                      style={{ flexDirection: "unset" }}
                    >
                      <label
                        style={{ paddingTop: "30px", paddingRight: "20px" }}
                      >
                        Rp.
                      </label>
                      <Input
                        // type="number"
                        name="dp"
                        label="Uang Muka (DP)"
                        onChange={this._onFormChange}
                        value={form.value.dp}
                        error={form.error.dp}
                        isNumber
                        isRequired
                        disabled={form.value.dp_payments_date ? true : false}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field"></div>
                      <div
                        className="kode-matpel__custom-form-column__field"
                        style={{ flexDirection: "unset" }}
                      >
                        <label
                          style={{ paddingTop: "30px", paddingRight: "20px" }}
                        >
                          Rp.
                        </label>
                        <Input
                          name="ttl"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.ttl}
                          error={form.error.ttl}
                          isNumber
                          style={{ backgroundColor: "lightgrey" }}
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  {form.value.ttl == 0 && payments_date.length == 0 ? null : (
                    <div style={{ padding: "20px 50px" }}>
                      <table
                        className="manage-rapbs-plafon__form-table table"
                        style={{ marginBottom: "1rem" }}
                      >
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Cicilan</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(cicilan, (list, idx) => (
                            <tr key={`plafon_journal_data__${idx}`}>
                              <td style={{ textAlign: "center" }}>
                                {idx + 1}.
                              </td>
                              <td>
                                <div style={{ display: "flex" }}>
                                  <label
                                    style={{
                                      paddingTop: "30px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    Rp.
                                  </label>
                                  <Input
                                    // type="number"
                                    name="total"
                                    label={"Cicilan " + (idx + 1) + " *"}
                                    onChange={this._onFormChange}
                                    value={list.total}
                                    // error={form.error.dp}
                                    error={list.errcicil}
                                    data-input-array
                                    arrayPosition={idx}
                                    data-array-position={idx}
                                    data-field-name="cicilan"
                                    isNumber
                                    isRequired
                                    disabled={
                                      list.payments_date != null ? true : false
                                    }
                                  />
                                </div>
                              </td>
                              {!isEdit || list.payments_date == null ? (
                                <td>
                                  <Button
                                    onClick={() => this._onDeleteList(idx)}
                                    icon="icon-trash-o"
                                  ></Button>
                                </td>
                              ) : (
                                <td
                                  className="reportspp"
                                  style={{
                                    color: "green",
                                    textAlign: "center",
                                  }}
                                >
                                  LUNAS
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* <div className="kode-matpel__custom-form-row">
                        <div className="kode-matpel__custom-form-row__field" style={{flexDirection: "unset"}}>
                        </div>
                        <div className="kode-matpel__custom-form-column__field" style={{marginBottom: "1rem"}}>
                          <label style={{fontSize: "1.6rem"}}>Sisa Cicilan: Rp. {commaFormatted(sisacicil)}</label>
                        </div>
                      </div> */}
                      <div>
                        <Button icon="icon-plus" onClick={this._onAddList} />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="student-list__header">
            <div className="student-list__button">
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CreateInvoiceTidakRutinGeneral.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CreateInvoiceTidakRutinGeneral.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
